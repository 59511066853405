<template>
  <div class="new">
    <div class="new-banner">
      <img :src="require('@imgs/vrbuild/vrbuild.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/vrbuild/web/vrbuild.png')" v-else />
      <div class="new-banner-title">
        <h3>VR专业建设</h3>
        <!-- <span> VR PROFESSIONAL CONSTRUCTION</span> -->
      </div>
    </div>
    <div class="new-breadcrumb">
      当前位置:<span @click="$router.push({ path: '/' })">首页</span> ->
      <span @click="$router.push({ path: '/vr-build' })">VR专业建设</span> ->
      <span>详情</span>
    </div>
    <div class="new-detail">
      <div class="new-detail-title">
        {{ info.title }}
      </div>
      <div class="new-detail-info">
        <!-- <span>作者:虚拟现实教育工作委员会</span> <span>发布日期:{{info.createTime}}</span> <span>浏览次数:1366</span> -->
      </div>
      <div
        class="new-detail-content ql-editor"
        v-html="info.content"
        :style="$isMobile() ? 'padding:15px 20px;' : ''"
      ></div>
      <div class="new-detail-but" @click="$router.back()">返回列表</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "newDetail",
  data() {
    return {
      info: {},
    };
  },
  computed: {
    newTabItem() {
      return this.$route.query.type;
    },
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      let url = `/train/${id}`;
      if (this.newTabItem === "VR教材建设") {
        url = `/construction/${id}`;
      }
      if (this.newTabItem === "VR师资培训") {
        url = `/training/${id}`;
      }
      if (this.newTabItem === "VR专业课程教学平台") {
        url = `/platform/${id}`;
      }
      this.$http({
        url,
      }).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.new {
  position: relative;
  &-banner {
    width: 100%;
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;
      h3 {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 0.8;
      font-family: Microsoft YaHei;
      }
      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        width: 400px;
        display: inline-block;
      font-family: Microsoft YaHei;
      }
    }
  }
  &-breadcrumb {
    max-width: 1100px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }
  &-detail {
    max-width: 1100px;
    margin: 0 auto;
    background: #fff;
    &-title {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      color: #333333;
      padding: 30px 0;
      border-bottom: 1px dotted #979797;
      font-family: Microsoft YaHei;
    }
    &-but {
      padding: 12px 0;
      text-align: center;
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      cursor: pointer;
      font-family: Microsoft YaHei;
    }
    &-content {
      max-width: 1000px;
      margin: 0 auto;
      padding-top: 30px;
    }
  }
}
</style>
